import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Anchor from "../elements/Anchor";
// import { ArrowLeft, ArrowRight } from "./Icons";

const Pagination = ({ enableArrows, pageInfo, base }) => {
  if (!pageInfo) return null;
  if (pageInfo.pageCount < 2) return null;

  const { currentPage, hasNextPage, hasPreviousPage, pageCount } = pageInfo;

  return (
    <nav role="navigation" aria-label="Pagination navigation">
      <ul className="pagination align-items-center justify-content-center flex-wrap my-4 fw-bold">
        {/* --- Previous button --- */}
        {enableArrows && hasPreviousPage && (
          <li className={`page-item${!hasPreviousPage ? " disabled" : ""}`}>
            <Anchor
              href={
                currentPage - 1 === 1
                  ? `${base}`
                  : `${base}page/${currentPage - 1}`
              }
              className="page-link border-0"
              rel="previous"
              aria-label="Navigate to previous page"
            >
              {/* <ArrowRight /> */}
              <StaticImage
                src="../../images/icons/nav-prev.png"
                alt="left arrow"
              />
            </Anchor>
          </li>
        )}

        {/* --- Pages links --- */}
        {/* {Array.from({ length: pageCount }, (_, index) => {
          const active = index + 1 === currentPage;

          return (
            <li
              className={`page-item${active ? " active" : ""}`}
              aria-current={active ? "page" : null}
              key={index}
            >
              {active ? (
                <span className="page-link" aria-label="Current page">
                  {index + 1}
                </span>
              ) : (
                <Anchor
                  href={index === 0 ? `/${base}` : `/${base}page/${index + 1}`}
                  className="page-link"
                  aria-label={`Navigate to page ${index + 1}`}
                >
                  {index + 1}
                </Anchor>
              )}
            </li>
          );
        })} */}

        {/* --- Next button --- */}
        {enableArrows && hasNextPage && (
          <li className={`page-item${!hasNextPage ? " disabled" : ""}`}>
            <Anchor
              href={`${base}page/${currentPage + 1}`}
              className="page-link border-0"
              rel="next"
              aria-label="Navigate to next page"
            >
              {/* <ArrowLeft /> */}
              <StaticImage
                src="../../images/icons/nav-next.png"
                alt="right arrow"
              />
            </Anchor>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;
